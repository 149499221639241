<template>
  <div>
    <div
      v-loading="loads.loading3"
      class="text-center"
    >
      <template
        v-if="file_url && file_url.slice(-3) == 'pdf'"
      >
        <div @click="openPdf(storageUrl + file_url)">
          <pdf
            :src="storageUrl + file_url"
            height="162"
            width="162"
            class="img-uploaded"
          />
        </div>
      </template>
      <template v-else-if="file_url">
        <img
          :src="storageUrl + file_url"
          height="162"
          width="162"
          class="img-uploaded"
        >
      </template>
      <template v-else>
        <img
          src="@/assets/images/svg/fileuploader-placeholder.svg"
          class="img-placeholder"
          alt="image"
          width="162"
          @click="$refs.fileRef.click()"
        >
      </template>

      <div>
        <input
          ref="fileRef"
          accept="image/jpeg, image/jpg, image/png, application/pdf"
          type="file"
          class="d-none"
          @change="(e) => onChangeFile(e, 1)"
        >
        <span
          class="upload-label"
          @click="$refs.fileRef.click()"
        >
          <template v-if="file_url">
            <feather-icon
              icon="CheckIcon"
              size="12"
            />
            {{ title }}
            <feather-icon
              icon="Trash2Icon"
              size="12"
            />
          </template>
          <template v-else>
            <feather-icon
              icon="PlusSquareIcon"
              size="12"
            />
            {{ title }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import pdf from 'vue-pdf'

export default {
  components: { pdf },
  directives: {
  },
  props: {
    orderid: {
      type: [Number, String],
      default: () => null,
    },
    title: {
      type: String,
      default: () => 'Faylni yuklang',
    },
    directory: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loading: false,
      loads: {
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
      },
      file_url: '',
    }
  },
  computed: {
    ...mapGetters({ contract: 'contract/GET_ITEM' }),
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    storageContractUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/contracts/`
    },
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
    }),

    openPdf(link) {
      window.open(link, '_blank')
    },

    async onChangeFile(event, type) {
      console.log(event, type)
      if (event.target.files[0].size <= 21360000) {
        this.loads[`loading${type}`] = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', this.directory)
        this.fileUpload(fileData)
          .then(res => {
            this.file_url = res.data.path
            this.$emit('file_url', this.file_url)
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loads[`loading${type}`] = false
          })
      } else {
        showToast('danger', this.$t('Файл ҳажми 20 МБ дан кичик бўлиши лозим'), 'XIcon')
      }
    },
    deleteItem(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAddDocument(item.id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getAction()
            })
          }
        })
    },

  },
}
</script>
