<template>
  <b-modal
    v-model="visible"
    size="lg"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="$t('Investor')"
              label-for="investor"
            >
              <validation-provider
                #default="{ errors }"
                name="investor"
                rules="required"
              >
                <v-select
                  id="tag"
                  v-model="investorObj"
                  placeholder="Investorni tanlang"
                  :options="investors.data"
                  :state="errors.length > 0 ? false : null"
                  :get-option-label="option => option.lastname +' ' + option.firstname + ' ' +option.middlename"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <span>
                      {{ option.lastname +' ' + option.firstname + ' ' +option.middlename }}
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Valyuta kursi(so\'m)')"
              label-for="dollar_rate"
            >
              <validation-provider
                #default="{ errors }"
                name="dollar_rate"
                rules="required"
              >
                <cleave
                  id="dollar_rate"
                  v-model="form.dollar_rate"
                  class="form-control"
                  :options="cleaveOption"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Investitsiya valyutasi')"
              label-for="currency"
            >
              <validation-provider
                #default="{ errors }"
                name="currency"
                rules="required"
              >
                <b-form-select
                  id="currency"
                  v-model="currency_id"
                  :state="errors.length > 0 ? false : null"
                  name="currency"
                  value-field="id"
                  text-field="name"
                  :placeholder="$t('Валюта прихода')"
                  :options="currencies"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              v-if="currency_id==1"
              :label="$t('Сумма в USD')"
              label-for="amount_dollar"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Сумма')"
                rules="required"
              >
                <cleave
                  id="amount_dollar"
                  v-model="form.amount_dollar"
                  class="form-control"
                  :state="errors.length > 0 ? false:null"
                  :options="cleaveOption"
                  placeholder="0"
                  :disabled="!form.dollar_rate"
                  @input="onchangeAmountDollar(form.amount_dollar)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-else
              :label="$t('Сумма в UZS')"
              label-for="amount"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Сумма')"
                rules="required"
              >
                <cleave
                  id="amount"
                  v-model="form.amount"
                  class="form-control"
                  :state="errors.length > 0 ? false:null"
                  :options="cleaveOption"
                  :disabled="!form.dollar_rate"
                  placeholder="0"
                  @input="onchangeAmount(form.amount)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <p class="mt-2">
              <b>{{ currency_id==2?'Сумма в USD':'Сумма в UZS' }}: </b> {{ (currency_id==1?form.amount:form.amount_dollar) | formatPrice }}
            </p>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Kontraktni yuklang"
              label-for="contract_url"
            >
                <v-upload
                  directory="contracts"
                  @file_url="setFileUrl"
                />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import VUpload from '@/components/VUpload.vue'

export default {
  name: 'Create',
  components: {
    VUpload,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
  },
  data() {
    return {
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      currencies: [{ id: 1, name: 'USD(dollar)' }, { id: 2, name: "UZS(so'm)" }],
      currency_id: 1,
      form: {
        id: null,
        investor_id: null,
        amount_dollar: null,
        amount: null,
        dollar_rate: null,
        contract_url: '',
      },
      investorObj: '',
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      investors: 'investment/GET_ITEMS',
      cities: 'city/GET_ITEMS',
      regions: 'region/GET_ITEMS',
    }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
    investorObj(newVal) {
      this.form.investor_id = newVal.id
    },
  },
  mounted() {
    this.title = this.$t('Investitsiya kiritish')
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.getItemsAction({ per_page: 999 }).then((res) => {
      this.investorObj = res.data.data.find(el=>el.id == this.$route.params.id)
    })
  },
  methods: {

    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    onchangeAmountDollar(val) {
      if (!this.form.dollar_rate) {
        showToast('warning', this.$t('Dollar kursini kiriting'))
      } else if (val) {
        this.form.amount = Number(this.form.dollar_rate) * Number(val)
      }
    },
    onchangeAmount(val) {
      if (!this.form.dollar_rate) {
        showToast('warning', this.$t('Dollar kursini kiriting'))
      } else if (val && this.currency_id == 2) {
        this.form.amount_dollar = (Number(val) / Number(this.form.dollar_rate)).toFixed(1)
      }
    },
    setFileUrl(url) {
      this.form.contract_url = url
    },
    edit(item) {
      this.getItem({
        id: item.id,
      }).then(res => {
        this.form.id = res.data.id

        this.visible = true
      })
    },
    method(data) {
      // if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      getRegionsAction: 'region/index',
      getItemsAction: 'investment/index',
      fetchCities: 'city/index',
      storeItem: 'investment/investmentStore',
      getItem: 'investment/show',
      updateItem: 'investment/update',
    }),
  },
}
</script>

<style scoped></style>
