var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Investor'),"label-for":"investor"}},[_c('validation-provider',{attrs:{"name":"investor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"tag","placeholder":"Investorni tanlang","options":_vm.investors.data,"state":errors.length > 0 ? false : null,"get-option-label":function (option) { return option.lastname +' ' + option.firstname + ' ' +option.middlename; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(" "+_vm._s(option.lastname +' ' + option.firstname + ' ' +option.middlename)+" ")])]}}],null,true),model:{value:(_vm.investorObj),callback:function ($$v) {_vm.investorObj=$$v},expression:"investorObj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Valyuta kursi(so\'m)'),"label-for":"dollar_rate"}},[_c('validation-provider',{attrs:{"name":"dollar_rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"dollar_rate","options":_vm.cleaveOption,"placeholder":"0"},model:{value:(_vm.form.dollar_rate),callback:function ($$v) {_vm.$set(_vm.form, "dollar_rate", $$v)},expression:"form.dollar_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Investitsiya valyutasi'),"label-for":"currency"}},[_c('validation-provider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"currency","state":errors.length > 0 ? false : null,"name":"currency","value-field":"id","text-field":"name","placeholder":_vm.$t('Валюта прихода'),"options":_vm.currencies},model:{value:(_vm.currency_id),callback:function ($$v) {_vm.currency_id=$$v},expression:"currency_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[(_vm.currency_id==1)?_c('b-form-group',{attrs:{"label":_vm.$t('Сумма в USD'),"label-for":"amount_dollar"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Сумма'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount_dollar","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0","disabled":!_vm.form.dollar_rate},on:{"input":function($event){return _vm.onchangeAmountDollar(_vm.form.amount_dollar)}},model:{value:(_vm.form.amount_dollar),callback:function ($$v) {_vm.$set(_vm.form, "amount_dollar", $$v)},expression:"form.amount_dollar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('b-form-group',{attrs:{"label":_vm.$t('Сумма в UZS'),"label-for":"amount"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Сумма'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"disabled":!_vm.form.dollar_rate,"placeholder":"0"},on:{"input":function($event){return _vm.onchangeAmount(_vm.form.amount)}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"mt-2"},[_c('b',[_vm._v(_vm._s(_vm.currency_id==2?'Сумма в USD':'Сумма в UZS')+": ")]),_vm._v(" "+_vm._s(_vm._f("formatPrice")((_vm.currency_id==1?_vm.form.amount:_vm.form.amount_dollar)))+" ")])]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Kontraktni yuklang","label-for":"contract_url"}},[_c('v-upload',{attrs:{"directory":"contracts"},on:{"file_url":_vm.setFileUrl}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }